import React, {useState} from 'react'
import s from './mail.module.scss'
import Select from 'react-select'
import {useSearchParams} from 'react-router-dom'
import CodeIcon from '@mui/icons-material/Code';
import wellcomeListHTML from '../../components/wellcomeListHTML'
import axios, { Axios } from 'axios'
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import AssignmentIcon from '@mui/icons-material/Assignment';
import { Collapse } from '@mui/material';

type Props = {}

const Mail = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const qemail = searchParams.get("email")
  const [err, setErr] = useState("")
  const [success, setSuccess] = useState("")
  const qsubj = searchParams.get("subject")
  const qbody = searchParams.get("body")
  const [dataForm, setDataForm] = useState({
    email: qemail || "",
    subject: qsubj || "",
    body_type: 0,
    body: qbody || "",
    access: "nvjbdjn32m"
  });

  const options = [
    { value: 'Щодо розвитку продажів та втілення скриптів, які продають', label: 'Щодо розвитку...' },
    { value: 'Щодо систематизації бізнесу, через втілення скриптів продаж', label: 'Щодо систематизації...' },
    { value: 'Щодо партнерства та розвитку продажів', label: 'Щодо партнерства...' }
  ]  

  const [abody, setAbody] = useState(false);

  const customStyles = {
    singleValue: (provided: any, state: { isDisabled: any }) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    },
    menu: (provided: any) => ({
      ...provided,
      width: "auto",
      right: "100%"
    }),
    control: (provided: any) => ({
      ...provided,
      // opacity: "0",
      border: "none",
      backgroundColor: "transparent !important"
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      display: "none",
    }),
  }

  const handleSubjPreset = (e: any) => {
    const value = e.value;
    setDataForm({...dataForm, subject: value})
  }
  
  const handleSubmit = async(e: any) => {
    e.preventDefault();
    await axios.post("https://smartscriptolog-mailer.vercel.app/", dataForm)
    .then((res) => {
      console.log(res.status)
      if (res.status == 200){
        
        setSuccess("Форма была отправлена!")
        console.log(success)
      }
    })
    .catch(function(error) {
      console.log(error);
      setErr(error.message)
    })
    
  }

  return (
    <div className={s.mail}>
      <div className={s.wrapper}>
        <h1>Отправка письма</h1>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className={s.alerts}>
            {err ? <Collapse  in={err.length > 0}><Alert aria-label="close" severity="error"action={<IconButton aria-label="close"color="inherit"size="small"onClick={() => {setErr("");}}><CloseIcon fontSize="inherit" /></IconButton>}sx={{ mb: 2 }}>{err}</Alert></Collapse> : <></>}
            {success ? <Collapse  in={success.length > 0}><Alert aria-label="close" severity="success"action={<IconButton aria-label="close"color="inherit"size="small"onClick={() => {setSuccess("");}}><CloseIcon fontSize="inherit" /></IconButton>}sx={{ mb: 2 }}>{success}</Alert></Collapse> : <></>}
          </div>
          <div className={s.input_wrapper}>
            <label htmlFor="email">Email:</label>
            <input required onChange={(e: any) => {setDataForm({...dataForm,email: e.target.value})}} value={dataForm.email} placeholder='example@example.com' type="text" name="email" id='email' />
          </div>

          <div className={s.input_wrapper}>
            <label htmlFor="subject">Тема письма:</label>
            <div className={s.flex}>
              <input required onChange={(e: any) => {setDataForm({...dataForm,subject: e.target.value})}} value={dataForm.subject} placeholder='Example subject for mail' type="text" name="subject" id='subject' />
              <Select
                className={s.select} 
                isSearchable={false}
                options={options} 
                styles={customStyles}
                onChange={handleSubjPreset}
              />
            </div>
          </div>

          <h4>Выберите наполняемость письма:</h4>
          <div className={s.radio_wrapper}>
            <input required onChange={() => {setAbody(false); setDataForm({...dataForm, body_type: 2})}} type="radio" name='template' id='radio1' />
            <label className={s.card} htmlFor='radio1'>
              <div className={s.wrapper_card}>
                <h3>Основной (рекомендуется)</h3>
                <img src='/previews/preview1.png' alt='img'/>
              </div>
            </label>
            
            <input onChange={() => {setAbody(true); setDataForm({...dataForm, body_type: 1})}} type="radio" name='template' id='radio2' />
            <label className={s.card} htmlFor='radio2'>
              <div className={s.wrapper_card}>
                <h3>Текст</h3>
                <div><AssignmentIcon/></div>
              </div>
            </label>

            <input onChange={() => {setAbody(true); setDataForm({...dataForm, body_type: 0})}} type="radio" name='template' id='radio3' />
            <label className={s.card} htmlFor='radio3'>
              <div className={s.wrapper_card}>
                <h3>Код (HTML)</h3>
                <div><CodeIcon/></div>
              </div>
            </label>
          </div>
          
          <textarea style={{display: abody ? "" : "none"}} onChange={(e: any) => {setDataForm({...dataForm,body: e.target.value})}} value={dataForm.body} name='body'/>
          
          <div className={s.btnw}><button type="submit">Отправить</button> </div>
        </form>
      </div>
    </div>
  )
}

export default Mail