import React, { FC, SetStateAction, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import s from './Nav.module.scss'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import ReviewsIcon from '@mui/icons-material/Reviews';

type NavProps = {
  dataActive: boolean,
  setDataActive: React.Dispatch<React.SetStateAction<boolean>>
}



export const Navbar= ({dataActive, setDataActive}: NavProps) => {
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [photoURL, setphotoURL] = useState("/defaultavatar.png")

  return (
    <div className={s.navbar}>
      <div className={s.wrapper}>
        <div data-active={dataActive} className={`${s.burger}`} onClick={() => {setDataActive(true)}}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={s.user}>
          <div className={s.logo}><img src={photoURL} alt='avatar'/></div>
          <div>
            <div className={s.name}>{`Smart Scriptolog`}</div>
            <div className={s.role}>{`Admin`}</div>
          </div>
          {/* <div className={s.btn}><KeyboardArrowUpIcon/></div> */}
        </div>
      </div>
    </div>
  )
}

type SideProps = {
  dataActive: boolean,
  setDataActive: React.Dispatch<React.SetStateAction<boolean>>

}

export const Sidebar = ({dataActive, setDataActive}: SideProps) => {
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  const logOut = () => {
    // e.preventDefault();
    localStorage.removeItem("isAuth");
    return navigate("../login", { replace: true })
  }  

  const Navigation = () => {
    return(<>
      <h3>Основное</h3>
      <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/home" onClick={() => {setDataActive(false)}} >
        <li>
          <DashboardIcon/>
          <span>Главная</span>
        </li>
      </NavLink>

      {/* CLIENTS */}
      <h3>Клиенты</h3>
      <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/mail" onClick={() => {setDataActive(false)}}>
        <li>
          <EmailIcon/>
          <span>Отправить письмо</span>
        </li>
      </NavLink>
    </>)
  }

  return (
    <div data-active={dataActive} className={s.sidebar}>
      <div className={s.wrapper}>
        <div className={s.logo}>S Script</div>
        <nav>
          <ul>
            {/* MAIN */}
            <Navigation/>

            <a href='#' onClick={logOut}>
              <li>
                <LogoutIcon/>
                <span>Выйти из системы</span>
              </li>
            </a>
          </ul>
        </nav>
      </div>
    </div>
  )
}

// export default {Navbar, Sidebar}