import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
// import {UserAuth} from '../../context/AuthContext'

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Collapse } from '@mui/material';

// type Props = {}

const Login = (props) => {
  const [email, setEmail] = useState("")
  const [pass, setPass] = useState("")
  const [err, setErr] = useState("")
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async(e) => {
    e.preventDefault();
    setErr('')
    if (email == "smartscriptolog" && pass == "smart158script23nf") {
      localStorage.setItem("isAuth", "1")
      return navigate('../home', { replace: true });
    }
    else{
      setErr("Login was failed!")
    }
  }

  useEffect(() => {
    if (localStorage.getItem("isAuth") == "1") {
      return navigate('../home', { replace: true });
    }
  })

  return (
    <div style={{maxWidth: "600px", margin: "100px auto", background: "#f2f2f2", padding: "48px 24px", textAlign: "center", borderRadius: "12px"}}>
      <h1>Smart Scriptolog</h1>
      <h3 style={{fontSize: "24px", margin: "12px auto 24px"}}>Login</h3>
      <form onSubmit={handleSubmit}>
        <div style={{display: "flex"}}>
          {err ? <Collapse  in={err.length > 0}><Alert aria-label="close" severity="error"action={<IconButton aria-label="close"color="inherit"size="small"onClick={() => {setErr("");}}><CloseIcon fontSize="inherit" /></IconButton>}sx={{ mb: 2 }}>{err}</Alert></Collapse> : <></>}
        </div>
        <label style={{display: "block", textAlign: "left"}} htmlFor='username'>Username</label>
        <input style={{display: "block", width: "100%", padding: "10px 20px", borderRadius: "8px", outline: "none"}} required placeholder='username' id='username' type="text" onChange={(e) => setEmail(e.target.value)}/><br/>
        <label style={{display: "block", textAlign: "left"}} htmlFor='pass'>Password</label>
        <input style={{display: "block", width: "100%", padding: "10px 20px", borderRadius: "8px", outline: "none"}} minlength="8" required placeholder='password' id='pass' type="password" onChange={(e) => setPass(e.target.value)}/><br/>
        {/* <label>Password</label>
        <input/> */}
        <button style={{padding: "12px 24px", borderRadius: "8px", border: "1px, solid #000"}} type='submit'>Login</button>
      </form>
    </div>
  )
}

export default Login