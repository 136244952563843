import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Shared/Layout';
import ProtectedRoute from './components/Shared/protectedRoute';
import { AuthContextProvider } from './context/AuthContext';
import { NotFound } from './pages/404/notfound';
import Clients from './pages/Clients/Clients';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/login/login';
import Mail from './pages/mail/mail';

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <Routes>
          <Route path='/home' element={<ProtectedRoute><Layout><Dashboard/></Layout></ProtectedRoute>}/>

          <Route path='/clients' element={<ProtectedRoute><Layout><Clients/></Layout></ProtectedRoute>}/>
          <Route path='/mail' element={<ProtectedRoute><Layout><Mail/></Layout></ProtectedRoute>}/>

          <Route path='/login' element={<Login/>}/>

          <Route path='*' element={<NotFound />}/>
          
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
