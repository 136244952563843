import React from 'react'
// import s from "./dashboard.module.scss";

type Props = {}

const Dashboard = (props: Props) => {
  return (
    <div>
      Тут пока что пусто!
    </div>
  )
}

export default Dashboard