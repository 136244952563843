import { useEffect } from "react"
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("isAuth") == "1") {
            return navigate('../home', { replace: true });
        }
        else{
            return navigate('../login', { replace: true });
        }
    })
    return(
        <>
            Page not Found! Error 404 :(
        </>
    )
}