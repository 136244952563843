import React, { useState } from 'react'
import { Navbar, Sidebar } from '../Navigation/Navigation'
import s from '../Navigation/Nav.module.scss'

type Props = {
    children?: React.ReactChild | React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  const [dataActive, setDataActive] = useState(false)
  return (
    <div className='layout'>
        <Sidebar dataActive={dataActive} setDataActive={setDataActive}/>
        <div className={s.background} style={{display: dataActive ? "block" : "none"}} onClick={() => {setDataActive(false)}}></div>
        <div className='left_col'>
          <Navbar dataActive={dataActive} setDataActive={setDataActive}/>
          <main>{children}</main>
        </div>
    </div>
  )
}

export default Layout