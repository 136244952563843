import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';


const ProtectedRoute = ({ children }) => {
  const [role, setRole] = useState("");
  console.log(localStorage.getItem("isAuth"))
  const navigate = useNavigate();
  useEffect(() => {
    if(localStorage.getItem("isAuth") != "1"){
      return navigate("../login", { replace: true })
    }
  })
  return children;
};

export default ProtectedRoute;